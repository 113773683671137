import { useState } from "react";
import { MyFooter } from "../components/MyFooter";
import { PageHeader } from "../components/PageHeader";
import emailjs from "emailjs-com";
import { Button } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";

export const ContactPage = () => {
  const [formValues, setFormValues] = useState({
    email_address: "",
    title: "",
    contents: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let flag = window.confirm("Would you like to send?");
    if (flag) {
      console.log("User confirmed to send.");
      console.log(formValues);
      emailjs
        .send(
          "service_twzv3yz",
          "template_2mx4c5h",
          formValues,
          "z7M1jOQGhm4gnogE4"
        )
        .then(
          (result) => {
            alert("Mail Send Complete.\nThank you to contact us!");
            console.log(result.text);
            // display success message to user
          },
          (error) => {
            alert("Mail Send Failed.\nPlease try again.");
            console.log(error.text);
            // display error message to user
          }
        );
    } else {
      alert("Mail Send Canceled.");
      console.log("Sending Canceled");
    }
  };

  return (
    <>
      {/* <video className="ContactOrbit" autoPlay loop muted>
        <source
          src={process.env.PUBLIC_URL + `/assets/videos/ContactOrbit.webm`}
        />
      </video> */}
      <BrowserView>
        <div>
          <PageHeader name="CONTACT" />
        </div>
        <form className="ContactForm" onSubmit={handleSubmit}>
          <div className="ContactPageContainer">
            <div className="ContactContentsContainer">
              <div className="ContactPageTitle">CONTACT</div>
            </div>
            <div className="ContactRow">
              <label htmlFor="name" className="ContactIndex">
                Client Email
              </label>
              <input
                className="RequestInput"
                type="email"
                id="email_address"
                name="email_address"
                value={formValues.email_address}
                onChange={handleInputChange}
                placeholder="Please enter your email."
              />
            </div>
            <div className="ContactRow">
              <label htmlFor="title" className="ContactIndex">
                Title
              </label>
              <input
                className="RequestInput"
                type="text"
                id="title"
                name="title"
                value={formValues.title}
                onChange={handleInputChange}
                placeholder="Please enter the title."
              />
            </div>
            <div>
              <label htmlFor="contents" className="ContactIndex">
                Content
              </label>
              <textarea
                className="RequestInput RequestInput_Contents"
                type="text"
                id="contents"
                name="contents"
                value={formValues.contents}
                onChange={handleInputChange}
                placeholder="Please enter the contents."
              />
            </div>
            <div className="SubmitBtn">
              <Button
                sx={{
                  maxHeight: "4vh",
                  height: "4vh",
                  width: "4vw",
                  fontSize: "2vh",
                }}
                variant="outlined"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>

        {/* <video
        muted
        autoPlay
        loop
        src={
          process.env.PUBLIC_URL +
          `/assets/videos/contact/ContactMotionsBg.webm`
        }
      /> */}
        <video
          className="ContactMotionOrbit"
          muted
          autoPlay
          loop
          src={process.env.PUBLIC_URL + `/ContactOrbitBg.webm`}
        />
        <video
          className="ContactMotionCloud"
          muted
          autoPlay
          loop
          src={process.env.PUBLIC_URL + `/ContactCloudBg.webm`}
        />
        <MyFooter />
      </BrowserView>
      <MobileView>
        <div>
          <PageHeader name="CONTACT" />
        </div>
        <form className="ContactForm" onSubmit={handleSubmit}>
          <div className="MobileContactPageContainer">
            <div className="ContactContentsContainer">
              <div className="MobileContactPageTitle">CONTACT</div>
            </div>
            <video
              className="MobileContactMotionOrbit"
              muted
              autoPlay
              loop
              src={process.env.PUBLIC_URL + `/ContactOrbitBg.webm`}
            />
            <video
              className="MobileContactMotionCloud"
              muted
              autoPlay
              loop
              src={process.env.PUBLIC_URL + `/ContactCloudBg.webm`}
            />
            <div className="MobileContactRow">
              <div htmlFor="name" className="MobileContactIndex">
                Client Email
              </div>
              <input
                className="MobileRequestInput"
                type="email"
                id="email_address"
                name="email_address"
                value={formValues.email_address}
                onChange={handleInputChange}
                placeholder="Please enter your email."
              />
            </div>
            <div className="MobileContactRow">
              <div htmlFor="title" className="MobileContactIndex">
                Title
              </div>
              <input
                className="MobileRequestInput"
                type="text"
                id="title"
                name="title"
                value={formValues.title}
                onChange={handleInputChange}
                placeholder="Please enter the title."
              />
            </div>
            <div>
              <div htmlFor="contents" className="MobileContactIndex">
                Content
              </div>
              <textarea
                className="MobileRequestInput MobileRequestInput_Contents"
                type="text"
                id="contents"
                name="contents"
                value={formValues.contents}
                onChange={handleInputChange}
                placeholder="Please enter the contents."
              />
            </div>
            <div className="MobileSubmitBtn">
              <Button
                sx={{
                  maxHeight: "4vh",
                  height: "4vh",
                  width: "4vw",
                  fontSize: "1.5vh",
                }}
                variant="outlined"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>

        {/* <video
        muted
        autoPlay
        loop
        src={
          process.env.PUBLIC_URL +
          `/assets/videos/contact/ContactMotionsBg.webm`
        }
      /> */}

        <MyFooter />
      </MobileView>
    </>
  );
};
